// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open


// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
 position: relative;
 display: block;
 min-height: (1rem * $line-height-base);
 padding-left: $custom-control-gutter;
}

.custom-control-inline {
 display: inline-flex;
 margin-right: $custom-control-spacer-x;
}

.custom-control-input {
 position: absolute;
 z-index: -1; // Put the input behind the label so it doesn't overlay text
 opacity: 0;

 &:checked ~ .custom-control-label::before {
 color: $custom-control-indicator-checked-color;
 @include gradient-bg($custom-control-indicator-checked-bg);
 @include box-shadow($custom-control-indicator-checked-box-shadow);
 }

 &:focus ~ .custom-control-label::before {
 // the mixin is not used here to make sure there is feedback
 box-shadow: $custom-control-indicator-focus-box-shadow;
 }

 &:active ~ .custom-control-label::before {
 color: $custom-control-indicator-active-color;
 background-color: $custom-control-indicator-active-bg;
 @include box-shadow($custom-control-indicator-active-box-shadow);
 }

 &:disabled {
 ~ .custom-control-label {
 color: $custom-control-label-disabled-color;

 &::before {
 background-color: $custom-control-indicator-disabled-bg;
 }
 }
 }
}

// Custom control indicators
//
// Build the custom controls out of psuedo-elements.

.custom-control-label {
 margin-bottom: 0;

 // Background-color and (when enabled) gradient
 &::before {
 position: absolute;
 top: (($line-height-base - $custom-control-indicator-size) / 2);
 left: 0;
 display: block;
 width: $custom-control-indicator-size;
 height: $custom-control-indicator-size;
 pointer-events: none;
 content: "";
 user-select: none;
 background-color: $custom-control-indicator-bg;
 @include box-shadow($custom-control-indicator-box-shadow);
 }

 // Foreground (icon)
 &::after {
 position: absolute;
 top: (($line-height-base - $custom-control-indicator-size) / 2);
 left: 0;
 display: block;
 width: $custom-control-indicator-size;
 height: $custom-control-indicator-size;
 content: "";
 background-repeat: no-repeat;
 background-position: center center;
 background-size: $custom-control-indicator-bg-size;
 }
}


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
 .custom-control-label::before {
 @include border-radius($custom-checkbox-indicator-border-radius);
 }

 .custom-control-input:checked ~ .custom-control-label {
 &::before {
 @include gradient-bg($custom-control-indicator-checked-bg);
 }
 &::after {
 background-image: $custom-checkbox-indicator-icon-checked;
 }
 }

 .custom-control-input:indeterminate ~ .custom-control-label {
 &::before {
 @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
 @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
 }
 &::after {
 background-image: $custom-checkbox-indicator-icon-indeterminate;
 }
 }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
 .custom-control-label::before {
 border-radius: $custom-radio-indicator-border-radius;
 }

 .custom-control-input:checked ~ .custom-control-label {
 &::before {
 @include gradient-bg($custom-control-indicator-checked-bg);
 }
 &::after {
 background-image: $custom-radio-indicator-icon-checked;
 }
 }
}


// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// http://primercss.io.
//

.custom-select {
 display: inline-block;
 width: 100%;
 height: $input-height;
 padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
 line-height: $custom-select-line-height;
 color: $custom-select-color;
 vertical-align: middle;
 background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
 background-size: $custom-select-bg-size;
 border: $custom-select-border-width solid $custom-select-border-color;
 @if $enable-rounded {
 border-radius: $custom-select-border-radius;
 } @else {
 border-radius: 0;
 }
 appearance: none;

 &:focus {
 border-color: $custom-select-focus-border-color;
 outline: 0;
 box-shadow: $custom-select-focus-box-shadow;

 &::-ms-value {
 // For visual consistency with other platforms/browsers,
 // suppress the default white text on blue background highlight given to
 // the selected option text when the (still closed) <select> receives focus
 // in IE and (under certain conditions) Edge.
 // See https://github.com/twbs/bootstrap/issues/19398.
 color: $input-color;
 background-color: $input-bg;
 }
 }

 &[multiple],
 &[size]:not([size="1"]) {
 height: auto;
 padding-right: $custom-select-padding-x;
 background-image: none;
 }

 &:disabled {
 color: $custom-select-disabled-color;
 background-color: $custom-select-disabled-bg;
 }

 // Hides the default caret in IE11
 &::-ms-expand {
 opacity: 0;
 }
}

.custom-select-sm {
 height: $custom-select-height-sm;
 padding-top: $custom-select-padding-y;
 padding-bottom: $custom-select-padding-y;
 font-size: $custom-select-font-size-sm;
}

.custom-select-lg {
 height: $custom-select-height-lg;
 padding-top: $custom-select-padding-y;
 padding-bottom: $custom-select-padding-y;
 font-size: $custom-select-font-size-lg;
}


// File
//
// Custom file input.

.custom-file {
 position: relative;
 display: inline-block;
 width: 100%;
 height: $custom-file-height;
 margin-bottom: 0;
}

.custom-file-input {
 position: relative;
 z-index: 2;
 width: 100%;
 height: $custom-file-height;
 margin: 0;
 opacity: 0;

 &:focus ~ .custom-file-control {
 border-color: $custom-file-focus-border-color;
 box-shadow: $custom-file-focus-box-shadow;

 &::before {
 border-color: $custom-file-focus-border-color;
 }
 }

 @each $lang, $value in $custom-file-text {
 &:lang(#{$lang}) ~ .custom-file-label::after {
 content: $value;
 }
 }
}

.custom-file-label {
 position: absolute;
 top: 0;
 right: 0;
 left: 0;
 z-index: 1;
 height: $custom-file-height;
 padding: $custom-file-padding-y $custom-file-padding-x;
 line-height: $custom-file-line-height;
 color: $custom-file-color;
 background-color: $custom-file-bg;
 border: $custom-file-border-width solid $custom-file-border-color;
 @include border-radius($custom-file-border-radius);
 @include box-shadow($custom-file-box-shadow);

 &::after {
 position: absolute;
 top: 0;
 right: 0;
 bottom: 0;
 z-index: 3;
 display: block;
 height: calc(#{$custom-file-height} - #{$custom-file-border-width} * 2);
 padding: $custom-file-padding-y $custom-file-padding-x;
 line-height: $custom-file-line-height;
 color: $custom-file-button-color;
 content: "Browse";
 @include gradient-bg($custom-file-button-bg);
 border-left: $custom-file-border-width solid $custom-file-border-color;
 @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
 }
}
