// .modal-open - body class for killing the scroll
// .modal - container to scroll within
// .modal-dialog - positioning shell for the actual modal
// .modal-content - actual modal w/ bg and corners and stuff


// Kill the scroll on the body
.modal-open {
 overflow: hidden;
}

// Container that the modal scrolls within
.modal {
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 z-index: $zindex-modal;
 display: none;
 overflow: hidden;
 // Prevent Chrome on Windows from adding a focus outline. For details, see
 // https://github.com/twbs/bootstrap/pull/10951.
 outline: 0;
 // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
 // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
 // See also https://github.com/twbs/bootstrap/issues/17695

 .modal-open & {
 overflow-x: hidden;
 overflow-y: auto;
 }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
 position: relative;
 width: auto;
 margin: $modal-dialog-margin;
 // allow clicks to pass through for custom click handling to close modal
 pointer-events: none;

 // When fading in the modal, animate it to slide down
 .modal.fade & {
 @include transition($modal-transition);
 transform: translate(0, -25%);
 }
 .modal.show & {
 transform: translate(0, 0);
 }
}

.modal-dialog-centered {
 display: flex;
 align-items: center;
 min-height: calc(100% - (#{$modal-dialog-margin} * 2));
}

// Actual modal
.modal-content {
 position: relative;
 display: flex;
 flex-direction: column;
 width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
 // counteract the pointer-events: none; in the .modal-dialog
 pointer-events: auto;
 background-color: $modal-content-bg;
 background-clip: padding-box;
 border: $modal-content-border-width solid $modal-content-border-color;
 @include border-radius($border-radius-lg);
 @include box-shadow($modal-content-box-shadow-xs);
 // Remove focus outline from opened modal
 outline: 0;
}

// Modal background
.modal-backdrop {
 position: fixed;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 z-index: $zindex-modal-backdrop;
 background-color: $modal-backdrop-bg;

 // Fade for backdrop
 &.fade { opacity: 0; }
 &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
 display: flex;
 align-items: flex-start; // so the close btn always stays on the upper right corner
 justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
 padding: $modal-header-padding;
 border-bottom: $modal-header-border-width solid $modal-header-border-color;
 @include border-top-radius($border-radius-lg);

 .close {
 padding: $modal-header-padding;
 // auto on the left force icon to the right even when there is no .modal-title
 margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
 }
}

// Title text within header
.modal-title {
 margin-bottom: 0;
 line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
 position: relative;
 // Enable `flex-grow: 1` so that the body take up as much space as possible
 // when should there be a fixed height on `.modal-dialog`.
 flex: 1 1 auto;
 padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
 display: flex;
 align-items: center; // vertically center
 justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
 padding: $modal-inner-padding;
 border-top: $modal-footer-border-width solid $modal-footer-border-color;

 // Easily place margin between footer elements
 > :not(:first-child) { margin-left: .25rem; }
 > :not(:last-child) { margin-right: .25rem; }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
 position: absolute;
 top: -9999px;
 width: 50px;
 height: 50px;
 overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
 // Automatically set modal's width for larger viewports
 .modal-dialog {
 max-width: $modal-md;
 margin: $modal-dialog-margin-y-sm-up auto;
 }

 .modal-dialog-centered {
 min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
 }

 .modal-content {
 @include box-shadow($modal-content-box-shadow-sm-up);
 }

 .modal-sm { max-width: $modal-sm; }

}

@include media-breakpoint-up(lg) {
 .modal-lg { max-width: $modal-lg; }
}
