// stylelint-disable declaration-no-important

// Typography

@mixin text-emphasis-variant($parent, $color) {
 #{$parent} {
 color: $color !important;
 }
 a#{$parent} {
 @include hover-focus {
 color: darken($color, 10%) !important;
 }
 }
}
