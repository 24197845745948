// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border-color` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus() {
 &:focus {
 color: $input-focus-color;
 background-color: $input-focus-bg;
 border-color: $input-focus-border-color;
 outline: 0;
 // Avoid using mixin so we can pass custom focus shadow properly
 @if $enable-shadows {
 box-shadow: $input-box-shadow, $input-focus-box-shadow;
 } @else {
 box-shadow: $input-focus-box-shadow;
 }
 }
}


@mixin form-validation-state($state, $color) {

 .#{$state}-feedback {
 display: none;
 width: 100%;
 margin-top: $form-feedback-margin-top;
 font-size: $form-feedback-font-size;
 color: $color;
 }

 .#{$state}-tooltip {
 position: absolute;
 top: 100%;
 z-index: 5;
 display: none;
 width: 250px;
 padding: .5rem;
 margin-top: .1rem;
 font-size: .875rem;
 line-height: 1;
 color: #fff;
 background-color: rgba($color, .8);
 border-radius: .2rem;
 }

 .form-control,
 .custom-select {
 .was-validated &:#{$state},
 &.is-#{$state} {
 border-color: $color;

 &:focus {
 border-color: $color;
 box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
 }

 ~ .#{$state}-feedback,
 ~ .#{$state}-tooltip {
 display: block;
 }
 }
 }

 .form-check-input {
 .was-validated &:#{$state},
 &.is-#{$state} {
 ~ .form-check-label {
 color: $color;
 }
 }
 }

 .custom-control-input {
 .was-validated &:#{$state},
 &.is-#{$state} {
 ~ .custom-control-label {
 color: $color;

 &::before {
 background-color: lighten($color, 25%);
 }
 }

 ~ .#{$state}-feedback,
 ~ .#{$state}-tooltip {
 display: block;
 }

 &:checked {
 ~ .custom-control-label::before {
 @include gradient-bg(lighten($color, 10%));
 }
 }

 &:focus {
 ~ .custom-control-label::before {
 box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-focus-width rgba($color, .25);
 }
 }
 }
 }

 // custom file
 .custom-file-input {
 .was-validated &:#{$state},
 &.is-#{$state} {
 ~ .custom-file-label {
 border-color: $color;

 &::before { border-color: inherit; }
 }

 ~ .#{$state}-feedback,
 ~ .#{$state}-tooltip {
 display: block;
 }

 &:focus {
 ~ .custom-file-label {
 box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
 }
 }
 }
 }
}
