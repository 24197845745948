.breadcrumb {
 display: flex;
 flex-wrap: wrap;
 padding: $breadcrumb-padding-y $breadcrumb-padding-x;
 margin-bottom: $breadcrumb-margin-bottom;
 list-style: none;
 background-color: $breadcrumb-bg;
 @include border-radius($border-radius);
}

.breadcrumb-item {
 // The separator between breadcrumbs (by default, a forward-slash: "/")
 + .breadcrumb-item::before {
 display: inline-block; // Suppress underlining of the separator in modern browsers
 padding-right: $breadcrumb-item-padding;
 padding-left: $breadcrumb-item-padding;
 color: $breadcrumb-divider-color;
 content: "#{$breadcrumb-divider}";
 }

 // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
 // without `<ul>`s. The `::before` pseudo-element generates an element
 // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
 //
 // To trick IE into suppressing the underline, we give the pseudo-element an
 // underline and then immediately remove it.
 + .breadcrumb-item:hover::before {
 text-decoration: underline;
 }
 // stylelint-disable-next-line no-duplicate-selectors
 + .breadcrumb-item:hover::before {
 text-decoration: none;
 }

 &.active {
 color: $breadcrumb-active-color;
 }
}
