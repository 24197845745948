// stylelint-disable declaration-no-important

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
 @include media-breakpoint-up($breakpoint) {
 $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

 .d#{$infix}-none { display: none !important; }
 .d#{$infix}-inline { display: inline !important; }
 .d#{$infix}-inline-block { display: inline-block !important; }
 .d#{$infix}-block { display: block !important; }
 .d#{$infix}-table { display: table !important; }
 .d#{$infix}-table-row { display: table-row !important; }
 .d#{$infix}-table-cell { display: table-cell !important; }
 .d#{$infix}-flex { display: flex !important; }
 .d#{$infix}-inline-flex { display: inline-flex !important; }
 }
}


//
// Utilities for toggling `display` in print
//

.d-print-block {
 display: none !important;

 @media print {
 display: block !important;
 }
}

.d-print-inline {
 display: none !important;

 @media print {
 display: inline !important;
 }
}

.d-print-inline-block {
 display: none !important;

 @media print {
 display: inline-block !important;
 }
}

.d-print-none {
 @media print {
 display: none !important;
 }
}
