// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
 @include media-breakpoint-up($breakpoint) {
 $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

 @each $prop, $abbrev in (margin: m, padding: p) {
 @each $size, $length in $spacers {

 .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
 .#{$abbrev}t#{$infix}-#{$size},
 .#{$abbrev}y#{$infix}-#{$size} {
 #{$prop}-top: $length !important;
 }
 .#{$abbrev}r#{$infix}-#{$size},
 .#{$abbrev}x#{$infix}-#{$size} {
 #{$prop}-right: $length !important;
 }
 .#{$abbrev}b#{$infix}-#{$size},
 .#{$abbrev}y#{$infix}-#{$size} {
 #{$prop}-bottom: $length !important;
 }
 .#{$abbrev}l#{$infix}-#{$size},
 .#{$abbrev}x#{$infix}-#{$size} {
 #{$prop}-left: $length !important;
 }
 }
 }

 // Some special margin utils
 .m#{$infix}-auto { margin: auto !important; }
 .mt#{$infix}-auto,
 .my#{$infix}-auto {
 margin-top: auto !important;
 }
 .mr#{$infix}-auto,
 .mx#{$infix}-auto {
 margin-right: auto !important;
 }
 .mb#{$infix}-auto,
 .my#{$infix}-auto {
 margin-bottom: auto !important;
 }
 .ml#{$infix}-auto,
 .mx#{$infix}-auto {
 margin-left: auto !important;
 }
 }
}
